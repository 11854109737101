import {getProductsList, getFilter,getFilters, getProductsListByTheme,getAllProducts} from 'api/productsApi'
const ProductDetailsPage = {
    
    state:()=>({
        productsList:{},
        searchedProductsList:{},
        filters_data: []
    }),

    mutations:{
        setProductsList(state, payload){
            state.productsList = payload
        },
        setSearchedProductsList(state, payload){
            state.searcgedProductsList = payload
        },
        setFiltersData(state, payload){
            state.filters_data =  payload
        }
    },

    getters:{

    },

    actions:{
        fetchProductsList({commit}, obj){
            // return getProductsList(obj)
            // .then(payload => {
            //     if (payload.status === 404) {
            //         console.log('fetching failed')
            //     } else {
            //         commit('setProductsList', payload)
            //         return payload
            //     }
            // })
            return getProductsListByTheme(obj.theme_id)
            .then(payload => {
                if (payload.status === 404) {
                    console.log('fetching failed')
                } else {
                    commit('setProductsList', payload.payload)
                    return payload
                }
            })
        },
        fetchAllProducts({commit},obj){
            return getAllProducts(obj)
            .then(payload => {
                if (payload.status === 404) {
                    console.log('fetching failed')
                } else {
                    commit('setProductsList', payload.payload)
                    return payload
                }
            })
        },
        fetchAllSearchedProducts({commit},obj){
            return getAllProducts(obj)
            .then(payload => {
                if (payload.status === 404) {
                    console.log('fetching failed')
                } else {
                    commit('setSearchedProductsList', payload.payload)
                    return payload
                }
            })
        },

        fetchFilters({commit}, {source,product_id}){

            return getFilter({source,product_id})
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setFiltersData', payload.filter_data)
                        return payload
                    }
                })
        },
        //for product list page
        fetchFilter({commit},product_id){

            return getFilters(product_id)
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setFiltersData', payload.payload)
                        return payload
                    }
                })
        }
    }

}

export default ProductDetailsPage