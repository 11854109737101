<template>
    <div class="autocomplete-wrapper" ref="root">
        <div class="autocomplete">
            <div
                class="autocomplete-input-wrapper autocomplete-input-wrapper-focus"
                :class="{
                    'autocomplete-input-wrapper-suggestion':
                        suggestions != null,
                }"
            >
                <div class="search-icon">
                    <template>
                        <span>
                            <svg
                                width="25"
                                height="24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="m19.742 21-5.146-5.134v-.813l-.278-.288a6.7 6.7 0 1 1 .72-.721l.289.278h.813l5.135 5.145L19.743 21h-.001zM9.964 5.058a4.631 4.631 0 1 0 0 9.262 4.631 4.631 0 0 0 0-9.262z"
                                    fill="#000"
                                />
                            </svg>
                        </span>
                    </template>
                    <!-- <template v-else>
                        <span class="autocomplete-loader"></span>
                    </template> -->
                </div>

                <input
                    class="autocomplete-input"
                    v-model="value"
                    type="text"
                    @input="onSearch"
                    @focus="focusToggle(true)"
                    @blur="focusToggle(false)"
                    @keydown.down="down"
                    @keydown.up="up"
                />
                <div class="clear-icon" @click="clearValue">
                    <span>
                        <svg
                            width="25"
                            height="24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.275 22A10 10 0 0 1 5.2 4.926a10.004 10.004 0 1 1 14.148 14.148A9.937 9.937 0 0 1 12.275 22zm0-8.59 3.59 3.59 1.41-1.41-3.59-3.59 3.59-3.59L15.865 7l-3.59 3.59L8.685 7l-1.41 1.41 3.59 3.59-3.59 3.59L8.685 17l3.59-3.589v-.001z"
                                fill="#000"
                            />
                        </svg>
                    </span>
                </div>
            </div>
            <div v-show="suggestions != null" class="autocomplete-suggestion">
                <template v-for="(suggestion, index) in suggestions">
                    <slot name="suggestion" :suggestion="suggestion">
                        <li
                            :key="index"
                            :class="{
                                'autocomplete-active': isActive(index),
                            }"
                        >
                            {{ suggestion.name }}
                        </li>
                    </slot>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchAutoComplete',
    props: {
        suggestionsArr: {
            type: Array,
        },
        addProduct: {
            type: Function,
        },
    },
    data() {
        return {
            value: '',
            isFocus: false,
            suggestions: null,
            current: -1,
            loading: false,
        }
    },
    watch: {
        suggestionsArr() {
            this.suggestions = this.suggestionsArr
        },
    },
    mounted() {
        const maindiv = document.querySelector('.autocomplete-suggestion')
        document.addEventListener('click', this.handleDocumentClick)
        maindiv.addEventListener('scroll', this.onScroll)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleDocumentClick)
    },
    methods: {
        onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (scrollTop + clientHeight >= scrollHeight - 80) {
                this.$emit('loadMoreSearchOptions', this.value)
            }
        },
        clearValue() {
            this.value = ''
            this.$emit('clear')
            this.suggestions = null
        },

        onSearch() {
            this.current = -1
            this.$emit('search', this.value)
        },

        focusToggle(bool) {
            this.isFocus = bool
            if (bool == true) {
                this.$emit('search', this.value)
            }
        },

        isActive(index) {
            return index === this.current
        },

        up() {
            if (this.current > -1) this.current--
        },

        down() {
            if (this.current < this.suggestions.length - 1) this.current++
        },

        handleDocumentClick(event) {
            if (this.$refs.root.contains(event.target)) {
                return
            }
            this.suggestions = null
        },
    },
}
</script>

<style lang="scss" scoped>
@import './SearchAutoComplete.scss';
</style>
