import { getCustomScene } from 'api/scenesApi'
import {
    getRelatedProduct,
    getProducts,
    getSearchedProducts,
} from 'api/productsApi'
import { DOMAIN_NAME, PRODUCT } from 'utils/constants'
import { getEHDDetails, getEHDDetailsv3 } from 'api/ehdApi'
import Cookies from 'universal-cookie'
import _ from 'lodash'

const cookies = new Cookies()

const CustomScenePage = {
    state: () => ({
        scene: {},
        relatedProducts: {},
        searchedProducts: {},
        clientSceneProduct: [],
        totalProductsCustomisation: {},
        totalServicesCustomisation: {},
    }),

    mutations: {
        setScene(state, payload) {
            state.scene = payload
        },
        setRelatedProducts(state, payload) {
            state.relatedProducts = payload
        },
        setSearchedProducts(state, payload) {
            state.searchedProducts = payload
        },
        setClientSceneProduct(state, payload) {
            state.clientSceneProduct = payload
        },
        setTotalProductsCustomisation(state, payload) {
            state.totalProductsCustomisation = payload
        },
        setTotalServicesCustomisation(state, payload) {
            state.totalServicesCustomisation = payload
        },
    },

    getters: {},

    actions: {
        fetchScenev3({ commit }, obj) {
            return getEHDDetailsv3(obj).then(response => {
                if (response.responseCode == 200) {
                    commit('setScene', response.data.scene_data[0])
                    // console.log(scene, 'data in module')
                    // commit('setScene', response)
                    return response
                } else {
                    commit('setScene', {})
                    return response
                }
            })
        },
        fetchScene({ commit }, obj) {
            return getCustomScene(obj.id).then(payload => {
                if (payload.status === 404) {
                    console.log('fetching failed')
                } else {
                    let scenes = payload.scene_data
                    let sceneData = []
                    let serviceData = []
                    let active = []
                    let removed = []

                    let activeServices = []
                    let removedServices = []
                    if (scenes.furnishing_products_in_scene.length == 0) {
                        active.push()
                        removed.push()
                    } else {
                        scenes.furnishing_products_in_scene.forEach(product => {
                            let count = 0

                            if (product.is_active == true) {
                                count++
                            }

                            if (count > 0) {
                                active.push(product)
                            } else {
                                removed.push(product)
                            }
                            // if (product.is_active == true) {
                            //     active.push(product)
                            // } else {
                            //     removed.push(product)
                            // }
                        })
                    }
                    if (scenes.decor_products_in_scene.length == 0) {
                        active.push()
                        removed.push()
                    } else {
                        scenes.decor_products_in_scene.forEach(product => {
                            if (product.is_active == true) {
                                active.push(product)
                            } else {
                                removed.push(product)
                            }
                        })
                    }
                    if (scenes.services_data.length == 0) {
                        activeServices.push()
                        removedServices.push()
                    } else {
                        scenes.services_data.forEach(service => {
                            if (service.active == true) {
                                activeServices.push(service)
                            } else {
                                removedServices.push(service)
                            }
                        })
                    }
                    sceneData = { active: active, removed: removed }
                    serviceData = {
                        active: activeServices,
                        removed: removedServices,
                    }
                    commit('setScene', payload)
                    commit('setTotalProductsCustomisation', sceneData)
                    commit('setTotalServicesCustomisation', serviceData)
                    return payload
                }
            })
            // return getEHDDetails(obj.ehd_id).then(response => {
            //     if (response.responseCode == 200) {
            //         response.data.scene_data.forEach(scene=>{
            //             if(scene.id===obj.scene_id){
            //                 commit('setScene',scene)
            //                 console.log(scene,"data in module")
            //             }
            //         })
            //         commit('setScene', response)
            //         return response
            //     } else {
            //          commit('setScene', {})
            //         return response
            //     }
            // })
        },

        fetchRelatedProducts({ commit }, { obj, config }) {
            commit('setRelatedProducts', {})
            return getRelatedProduct(obj, config).then(payload => {
                if (payload.status === 404) {
                    console.log('fetching failed')
                } else {
                    commit('setRelatedProducts', payload)
                    return payload
                }
            })
        },

        fetchSearchedProducts({ commit }, obj) {
            commit('setSearchedProducts', {})
            return getSearchedProducts(obj).then(payload => {
                if (payload.status === 404) {
                    console.log('fetching failed')
                } else {
                    commit('setSearchedProducts', payload)
                    return payload
                }
            })
        },

        fetchProducts({ commit }, page) {
            commit('setRelatedProducts', {})
            return getProducts(page).then(payload => {
                if (payload.status === 404) {
                    console.log('fetching failed')
                } else {
                    commit('setRelatedProducts', payload)
                    return payload
                }
            })
        },

        updateRelatedProducts({ commit }) {
            commit('setRelatedProducts', {})
        },
        updateSearchedProducts({ commit }) {
            commit('setSearchedProducts', {})
        },

        CLIENT_SCENE({ commit }, sceneProduct) {
            commit('setClientSceneProduct', sceneProduct)
        },
    },
}

export default CustomScenePage
